import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { PermissionData } from '@/modules/FacebookConnection/domain/models/Facebook'
import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import FacebookService from '@/components/configuration/Services/FacebookService'
import { EventBus } from '@/eventBus'
import Colors from '@/shared/domain/colors/Color'
import BillingValidations from '@/components/configuration/Billing/BillingValidations'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import ContextListMenu from '@/shared/infrastructure/ui/ContextListMenu.vue'
import { channelDisconnect, dataToDisconnectChannel } from '@/modules/Config/Channels/shared/domain/ChannelsController'
import ChannelsUseCases from '@/modules/Config/Channels/shared/application/ChannelsUseCases'
import EmitToast from '@/utils/EmitToast'
import ChannelsController from '@/modules/Config/Channels/shared/infrastructure/controllers/Channels.controller'
import { mapState } from 'vuex'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { ChannelsEnum } from '@/modules/Config/Channels/shared/domain/ChannelsEnum'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import DisconnectWarning from '@/modules/configuration/infrastructure/ui/components/NewChannels/DisconnectWarning/DisconnectWarning.vue'
export default {
  name: 'FacebookLogin.vue',
  components: { KBButton, KeybeIcon, ContextListMenu, DisconnectWarning },
  props: [
    'label',
    'appId',
    'loginParams',
    'buttonText',
    'extend',
    'tokenPercentage',
    'anotherLine',
    'config',
    'type'
  ],
  data: function () {
    return {
      loadingSDK: false,
      loadedSDK: false,
      showMenu: false,
      channelsController: new ChannelsController(),
      activityLogsController: new ActivityLogsController(),
      KeybeIconType,
      Colors,
      showModal: false,
      configData: null,
      showDisconnectWarning: false,
      loadingDisconnect: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    isDisabled () {
      return this.config?.enable === false || false
    },
    menuItems (): {
      text: string,
      height: number,
      width: number,
      callback: any
    }[] {
      return [
        {
          text: this.buttonText,
          height: 14,
          width: 14,
          callback: () => {
            if (!this.isUserReadonly) {
              this.showMenu = false
              this.connect()
            }
          }
        },
        {
          text: this.$t('disconnect'),
          height: 14,
          width: 14,
          callback: () => {
            if (!this.isUserReadonly) {
              this.showMenu = false
              this.disconnectFacebook()
            }
          }
        }
      ]
    },
    showModalFScreen: {
      get () {
        return this.$store.state.AppStore.showModalFScreen
      },
      set (_) {
        // this.$store.commit('AppStore/SHOW_MODAL_FULL_SCREEN', value)
      }
    },
    showModalData: {
      get () {
        return this.$store.state.AppStore.showModalData
      },
      set (_) {
        // this.$store.commit('AppStore/UPDATE_MODAL_FULL_SCREEN', value)
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async disconnectFacebook (): Promise<void> {
      this.loadingDisconnect = true
      const id = this.config?._id
      const data: dataToDisconnectChannel = {
        appUUID: this.selectedApp?.uuid,
        token: this.token,
        type: this.type,
        id: id
      }
      const response: channelDisconnect = await ChannelsUseCases.disconnectChannel(this.channelsController, data)
      if (!response.status) EmitToast.emitErrors(response.message)
      else {
        this.$emit('refresh')
      }
      this.showDisconnectWarning = false
      this.loadingDisconnect = false
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.CHANNELS,
          action: this.type === ChannelsEnum.INSTAGRAM
            ? ActivityActionTypeEnum.INSTAGRAM_DISCONNECTED
            : ActivityActionTypeEnum.FACEBOOK_DISCONNECTED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    openMenu (): void {
      this.showMenu = true
    },
    closeMenu (): void {
      this.showMenu = false
    },
    async initialize (): Promise<void> {
      try {
        if (!FacebookService.existsSDK()) {
          this.loadingSDK = true
          await FacebookService.injectSDK()
          this.loadingSDK = false
        }
        this.loadedSDK = true
      } catch (error) {
        this.loadingSDK = false
        EventBus.$emit('toast', 'error', this.$t('errorLoadingFacebookSDK'))
      }
    },
    getRejectedPermissions (_permissions: PermissionData[]): string[] {
      try {
        const requestedPermissions = this.loginParams?.scope?.split(',') || []
        const rejectedPermissions: string[] = []
        for (const permission of requestedPermissions) {
          const permissionData = _permissions.find((d) => d.permission === permission)
          if (!permissionData || permissionData.status !== 'granted') {
            rejectedPermissions.push(permission)
          }
        }
        return rejectedPermissions
      } catch (error) {
        console.error(error)
        throw new Error(this.$t('errorValidatingPermissions'))
      }
    },
    async connect (): Promise<void> {
      if (this.isUserReadonly) return
      if (!BillingValidations.hasBalance()) {
        return
      }

      if (FacebookProvider.getBusySDK()) {
        console.error('Facebook SDK is busy')
        EventBus.$emit('toast', 'error', this.$t('endConnectionInProcess'))
        return
      }
      if (this.loadingSDK) {
        return
      }
      if (!this.loadedSDK) {
        console.error('Facebook SDK not loaded')
        EventBus.$emit('toast', 'error', this.$t('unexpectedError'))
        return
      }
      try {
        FacebookProvider.setBusySDK(true)
        FacebookService.initializeSDK(this.appId)
        const loginResult = await FacebookService.login(this.loginParams)
        if (loginResult.status !== 'connected') {
          FacebookProvider.setBusySDK(false)
          return
        }
        const shortLivedAccessToken = loginResult.authResponse.accessToken
        const permissionsResult = await FacebookService.getPermissions(shortLivedAccessToken)
        const rejectedPermissions = this.getRejectedPermissions(permissionsResult.data)
        if (rejectedPermissions.length > 0) {
          throw new Error(this.$t('mustAcceptAllPermissions'))
        }
        this.$emit('gotAccessToken', shortLivedAccessToken)
      } catch (error) {
        FacebookProvider.setBusySDK(false)
        console.error('An error occurred during the connection process', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    }
  },
  watch: {
  },
  beforeDestroy () {
    ChannelsUseCases.abort(this.channelsController)
    this.showModalFScreen = false
    this.showModalData = {}
    this.showModal = false
  }
}
