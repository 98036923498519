import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { Component, Template, TemplateBody, TemplateButton, TemplateButtonTypesEnum, TemplateButtons, TemplateComponentsEnum, TemplateFooter, TemplateHeader, TemplateHeaderFormatEnum } from '../domain/Template'
import { ITemplatesController, dataToBuildTemplateBody, dataToBuildTemplateButton, dataToBuildTemplateButtons, dataToBuildTemplateFooter, dataToBuildTemplateHeader, dataToCreateTemplate, dataToGetTemplates, dataToDeleteTemplates, templateCreated, SequenceTemplate } from '../domain/TemplateController'
import TemplateRepository from './Template.repository'
import { i18n } from '@/plugins/i18n/i18n'

export default class TemplateController implements ITemplatesController {
  repository: TemplateRepository

  constructor () {
    this.repository = new TemplateRepository()
  }

  async createTemplate (data: dataToCreateTemplate): Promise<templateCreated> {
    const response: GenericResponse = await this.repository.createTemplate(data)
    return {
      status: response.status,
      message: response.data.message || response.message || ''
    }
  }

  buildTemplateHeader (data: dataToBuildTemplateHeader): TemplateHeader {
    const { format, text, example } = data
    if (format === TemplateHeaderFormatEnum.TEXT) {
      return {
        type: TemplateComponentsEnum.HEADER,
        format,
        text,
        example
      }
    }

    return {
      type: TemplateComponentsEnum.HEADER,
      format,
      example
    }
  }

  buildTemplateBody (data: dataToBuildTemplateBody): TemplateBody {
    return {
      type: TemplateComponentsEnum.BODY,
      text: data.text,
      example: data.example
    }
  }

  buildTemplateFooter (data: dataToBuildTemplateFooter): TemplateFooter {
    return {
      type: TemplateComponentsEnum.FOOTER,
      text: data.text
    }
  }

  buildTemplateButton (data: dataToBuildTemplateButton): TemplateButton {
    // eslint-disable-next-line camelcase
    const { type, text, url, phone_number } = data

    switch (type) {
      case TemplateButtonTypesEnum.PHONE_NUMBER:
        return {
          type,
          phone_number,
          text
        }
      case TemplateButtonTypesEnum.URL:
        return {
          type,
          url,
          text
        }
      default:
        return {
          type,
          text
        }
    }
  }

  buildTemplateButtons (data: dataToBuildTemplateButtons): TemplateButtons {
    return {
      type: TemplateComponentsEnum.BUTTONS,
      buttons: data.buttons
    }
  }

  async getTemplates (data: dataToGetTemplates): Promise<Template[]> {
    const response = await this.repository.getTemplates(data)

    return response?.data?.items || []
  }

  async deleteTemplate (data: dataToDeleteTemplates): Promise<boolean> {
    const response = await this.repository.deleteTemplate(data)
    console.log(response)

    return response?.data?.items || []
  }

  buildSequenceTemplate (data: SequenceTemplate): Component[] {
    const { text, cards } = data
    const components: Component[] = []

    const params: string[] | null = this.matchTextWithEditableParams(text)
    const example = []
    if (params) {
      params.forEach((param, index) => {
        example.push(`{{${index + 1}}}`)
      })
    }

    components.push({
      type: TemplateComponentsEnum.BODY,
      text: this.parseTextWithEditableParams(text),
      example: {
        body_text: [example]
      }
    })
    const templateCards = []

    cards.forEach(card => {
      const cardComponents: Component[] = []
      cardComponents.push({
        type: TemplateComponentsEnum.HEADER,
        format: TemplateHeaderFormatEnum.IMAGE,
        example: {
          header_handle: ['https://storage.keybeapi.com/utils/documents/chat/images/4xqa4b8khxrofef/9xre56f1lza3uzeo.jpeg']
        }
      })
      const params: string[] | null = this.matchTextWithEditableParams(card.text)
      const example = []
      if (params) {
        params.forEach((param, index) => {
          example.push(`{{${index + 1}}}`)
        })
      }
      cardComponents.push({
        type: TemplateComponentsEnum.BODY,
        text: this.parseTextWithEditableParams(card.text),
        example: {
          body_text: [example]
        }
      })
      const buttons = []
      card.buttons.forEach((button: TemplateButton) => {
        const obj: {
          text: string,
          type: string,
          url?: string,
          phone_number?: string,
          example?: string[]
        } = {
          type: button.type,
          text: button.text
        }

        if (button.url) {
          obj.url = button.url
        }

        if (button.phone_number) {
          obj.phone_number = button.phone_number
        }
        buttons.push(obj)
      })
      cardComponents.push({
        type: TemplateComponentsEnum.BUTTONS,
        buttons
      })

      templateCards.push({
        components: cardComponents
      })
    })
    components.push({
      type: TemplateComponentsEnum.CAROUSEL,
      cards: templateCards
    })
    return components
  }

  matchTextWithEditableParams (text: string): string[] {
    const textToTest = i18n.t('editableParam')
    const regex: RegExp = new RegExp(String(textToTest), 'g')
    return text.match(regex)
  }

  parseTextWithEditableParams (text: string): string {
    const params: string[] | null = this.matchTextWithEditableParams(text)

    if (!params) return text

    let newText = text

    for (let i = 0; i < params.length; i++) {
      newText = newText.replace(`[${params[i]}]`, `{{${i + 1}}}`)
    }

    return newText
  }
}
