export enum TemplateCategoryEnum {
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

export type TemplateCategoryType = TemplateCategoryEnum.MARKETING | TemplateCategoryEnum.UTILITY

export enum TemplateComponentsEnum {
    HEADER = 'HEADER',
    BODY = 'BODY',
    FOOTER = 'FOOTER',
    BUTTONS = 'BUTTONS',
    CAROUSEL = 'CAROUSEL',
  }

export type TemplateComponent = TemplateComponentsEnum.HEADER | TemplateComponentsEnum.BODY | TemplateComponentsEnum.FOOTER | TemplateComponentsEnum.BUTTONS | TemplateComponentsEnum.CAROUSEL;

export enum TemplateHeaderFormatEnum {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    DOCUMENT = 'DOCUMENT',
    LOCATION = 'LOCATION',
  }

export type TemplateHeaderFormat = TemplateHeaderFormatEnum.TEXT | TemplateHeaderFormatEnum.IMAGE | TemplateHeaderFormatEnum.VIDEO | TemplateHeaderFormatEnum.DOCUMENT;

export enum TemplateButtonTypesEnum {
    PHONE_NUMBER = 'PHONE_NUMBER',
    URL = 'URL',
    QUICK_REPLY = 'QUICK_REPLY',
    COPY_CODE = 'COPY_CODE',
  }

export type TemplateButtonType = TemplateButtonTypesEnum.PHONE_NUMBER | TemplateButtonTypesEnum.URL | TemplateButtonTypesEnum.QUICK_REPLY | TemplateButtonTypesEnum.COPY_CODE;

export interface TemplateHeader {
    type: TemplateComponentsEnum.HEADER,
    format: TemplateHeaderFormat,
    text?: string,
    example?: {[key: string]: any}
  }

export interface TemplateBody {
    type: TemplateComponentsEnum.BODY,
    text: string,
    example?: {[key: string]: any}
  }

export interface TemplateFooter {
    type: TemplateComponentsEnum.FOOTER,
    text: string,
  }

export interface TemplateButton {
    type: TemplateButtonType,
    text?: string,
    url?: string,
    phone_number?: string,
  }

export interface TemplateButtons {
    type: TemplateComponentsEnum.BUTTONS,
    buttons: TemplateButton[],
  }

export interface Component {
  type?: TemplateComponent,
  text?: string,
  format?: TemplateHeaderFormat,
  example?: {[key: string]: any},
  cards?: {
    components: Component[]
  }[],
  buttons?: TemplateButton[],
}
export interface Template {
  _id: string,
  appUUID: string,
  userHost: string,
  templateId: string,
  customName: string,
  provider: string,
  providerTemplateId: string,
  providerTemplateName: string,
  templateMessage: string,
  type: string,
  templateName: string,
  updatedAt: string,
  components: Component[],
  language: string,
  category: string,
}
