import { IChannelsController, channelDisconnect, dataToDisconnectChannel, dataToUpdateWhatsAppBusinessProfile, dataToUpdateWhatsAppBusinessProfilePhoto, dataTogGetWhatsAppBusinessProfile, whatsappBusinessProfile } from '../../domain/ChannelsController'
import ChannelsRepository from '../repository/Channels.repository'

export default class ChannelsController implements IChannelsController {
  repository: ChannelsRepository

  constructor () {
    this.repository = new ChannelsRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  async disconnectChannel (data: dataToDisconnectChannel) : Promise<channelDisconnect> {
    const response = await this.repository.disconnectChannel(data)
    return response
  }

  async getWhatsAppBusinessProfile (data: dataTogGetWhatsAppBusinessProfile) : Promise<whatsappBusinessProfile> {
    const response = await this.repository.getWhatsAppBusinessProfile(data)
    if (response.status) {
      return { status: true, data: response?.data[0] || {} }
    } else return { status: false, message: response?.message || '' }
  }

  async updateWhatsAppBusinessProfile (data: dataToUpdateWhatsAppBusinessProfile) : Promise<whatsappBusinessProfile> {
    const response = await this.repository.updateWhatsAppBusinessProfile(data)
    if (response.status) {
      return { status: true }
    } else return { status: false, message: response?.message || '' }
  }

  async updateWhatsAppBusinessProfilePhoto (data: dataToUpdateWhatsAppBusinessProfilePhoto) : Promise<whatsappBusinessProfile> {
    const response = await this.repository.updateWhatsAppBusinessProfilePhoto(data)
    if (response.status) {
      return { status: true }
    } else return { status: false, message: response?.message || '' }
  }
}
