export interface Template {
  _id: string,
  appUUID: string,
  userHost: string,
  templateId: string,
  customName: string,
  provider: string,
  providerTemplateId: string,
  providerTemplateName: string,
  templateMessage: string,
  type: string,
  templateName: string,
  updatedAt: string,
  components: string,
  language: string,
  category: string
}

export enum TemplateComponentsEnum {
  HEADER = 'HEADER',
  BODY = 'BODY',
  FOOTER = 'FOOTER',
  BUTTONS = 'BUTTONS',
  CAROUSEL = 'CAROUSEL',
}

export type TemplateComponent = TemplateComponentsEnum.HEADER | TemplateComponentsEnum.BODY | TemplateComponentsEnum.FOOTER | TemplateComponentsEnum.BUTTONS | TemplateComponentsEnum.CAROUSEL;

export enum TemplateHeaderFormatEnum {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  LOCATION = 'LOCATION',
}

export type TemplateHeaderFormat = TemplateHeaderFormatEnum.TEXT | TemplateHeaderFormatEnum.IMAGE | TemplateHeaderFormatEnum.VIDEO | TemplateHeaderFormatEnum.DOCUMENT;

export enum TemplateButtonTypesEnum {
  PHONE_NUMBER = 'PHONE_NUMBER',
  URL = 'URL',
  QUICK_REPLY_UPDATE = 'QUICK_REPLY_UPDATE',
  COPY_CODE = 'COPY_CODE',
}

export type TemplateButtonType = TemplateButtonTypesEnum.PHONE_NUMBER | TemplateButtonTypesEnum.URL | TemplateButtonTypesEnum.QUICK_REPLY_UPDATE | TemplateButtonTypesEnum.COPY_CODE;

export interface TemplateHeader {
  type: TemplateComponentsEnum.HEADER,
  format: TemplateHeaderFormat,
  text?: string,
}

export interface TemplateBody {
  type: TemplateComponentsEnum.BODY,
  text: string,
}

export interface TemplateFooter {
  type: TemplateComponentsEnum.FOOTER,
  text: string,
}

export interface TemplateButton {
  type: TemplateButtonType,
  text?: string,
  url?: string,
  phone_number?: string,
}

export interface TemplateButtons {
  type: TemplateComponentsEnum.BUTTONS,
  buttons: TemplateButton[],
}

export interface PhoneObject {
  code: string,
  number: string,
  phone: string,
  countryName?: string,
  countryCode: string,
  valid: boolean
}

export type TemplateComponentObjectType = TemplateHeader | TemplateBody | TemplateFooter | TemplateButtons;
