import { Component } from '../domain/Template'
import { ITemplatesController, SequenceTemplate, dataToBuildTemplateBody, dataToBuildTemplateButton, dataToBuildTemplateButtons, dataToBuildTemplateFooter, dataToBuildTemplateHeader, dataToCreateTemplate, dataToGetTemplates, templateCreated } from '../domain/TemplateController'

export default class TemplateUseCases {
  static getTemplates (controller: ITemplatesController, data: dataToGetTemplates) {
    return controller.getTemplates(data)
  }

  static buildTemplateHeader (controller: ITemplatesController, data: dataToBuildTemplateHeader) {
    return controller.buildTemplateHeader(data)
  }

  static buildTemplateBody (controller: ITemplatesController, data: dataToBuildTemplateBody) {
    return controller.buildTemplateBody(data)
  }

  static buildTemplateFooter (controller: ITemplatesController, data: dataToBuildTemplateFooter) {
    return controller.buildTemplateFooter(data)
  }

  static buildTemplateButton (controller: ITemplatesController, data: dataToBuildTemplateButton) {
    return controller.buildTemplateButton(data)
  }

  static buildTemplateButtons (controller: ITemplatesController, data: dataToBuildTemplateButtons) {
    return controller.buildTemplateButtons(data)
  }

  static createTemplate (controller: ITemplatesController, data: dataToCreateTemplate): Promise<templateCreated> {
    return controller.createTemplate(data)
  }

  static buildSequenceTemplate (controller: ITemplatesController, data: SequenceTemplate): Component[] {
    return controller.buildSequenceTemplate(data)
  }

  static matchTextWithEditableParams (controller: ITemplatesController, text: string): string[] {
    return controller.matchTextWithEditableParams(text)
  }

  static parseTextWithEditableParams (controller: ITemplatesController, text: string): string {
    return controller.parseTextWithEditableParams(text)
  }
}
