import { Component, TemplateComponentsEnum } from '@/modules/createTemplates/domain/Template'
import TemplateService from '@/modules/templates/domain/Template.service'

export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    templateId: {
      type: String,
      required: true
    }
  },
  mounted () {
    const body = this.card.components.find((component: Component) => component.type === TemplateComponentsEnum.BODY)
    this.templateMessage = body.text
    this.templateMessageUnfilled = body.text
    this.templateParams = TemplateService.getParams(
      this.templateMessage
    )
    setTimeout(() => {
      this.createParamsEventListener()
    }, 700)
  },
  data () {
    return {
      templateParams: [],
      templateMessage: '',
      templateMessageUnfilled: '',
      idTemplateInput: 'NewWhatsappForm__templateInputCard',
      templateVariables: {
        templateMessage: '',
        templateId: '',
        templateParams: [], // body params
        valid: false,
        error: ''
      },
      currentInput: null,
      currentCaretPosition: null,
      clipboardText: null
    }
  },
  methods: {
    parseTemplateMessage (): string {
      for (let i = 0; i < this.templateParams.length; i++) {
        this.templateMessage = this.templateMessage.replace(
          this.templateParams[i].name,
          `<span class="TemplateComposerCard__textarea" style="color: #B6BEC7; outline: none;" id="${
            this.idTemplateInput + 'Param' + i
          }" placeholder="${this.$t('yourTextHere')}" contenteditable="true"></span>`
        )
      }
      this.templateMessage = this.templateMessage.replace(/\n/g, '<br />')
      return `<span>${this.templateMessage}</span>`
    },
    createParamsEventListener (): void {
      this.templateParams.forEach(
        (_: { [key: string]: any }, index: number) => {
          const input = document.getElementById(this.idTemplateInput + 'Param' + index)
          if (input) {
            input.addEventListener('input', (event: {[key: string]: any}) => {
              this.onInputEvent(input, index, event)
            })

            input.addEventListener('paste', (event: ClipboardEvent) => {
              this.onPasteEvent(event)
            })

            input.addEventListener('keyup', (event: KeyboardEvent) => {
              this.onKeyupEvent(event, index)
            })

            input.addEventListener('click', (event: MouseEvent) => {
              this.onClickEvent(event, index)
            })

            input.addEventListener('focus', (event: FocusEvent) => {
              this.onFocusEvent(input, event, index)
            })

            input.addEventListener('blur', (event: FocusEvent) => {
              this.onBlurEvent(input, event)
            })
          }
        }
      )
    },
    destroyParamsEventListener (): void {
      this.templateParams.forEach(
        (_: { [key: string]: any }, index: number) => {
          const input = document.getElementById(this.idTemplateInput + 'Param' + index)
          if (!input) return

          input.removeEventListener('input', (event: {[key: string]: any}) => {
            this.onInputEvent(input, index, event)
          })

          input.removeEventListener('paste', (event: ClipboardEvent) => {
            this.onPasteEvent(event)
          })

          input.removeEventListener('keyup', (event: KeyboardEvent) => {
            this.onKeyupEvent(event, index)
          })

          input.removeEventListener('click', (event: MouseEvent) => {
            this.onClickEvent(event, index)
          })

          input.removeEventListener('focus', (event) => {
            this.onFocusEvent(input, event, index)
          })

          input.removeEventListener('blur', (event: FocusEvent) => {
            this.onBlurEvent(input, event)
          })
        })
    },
    onInputEvent (input: HTMLSpanElement, index: number, event: {[key: string]: any}) {
      if (input.style.display === 'inline-block') {
        input.style.display = input.textContent === '' ? 'inline-block' : 'inline'
      }

      if (event?.inputType === 'insertFromPaste') {
        input.textContent = event?.target?.textContent || ''

        // Set offset to the end of the pasted clipboard text
        let offset: number = event?.target?.textContent?.length || 0

        if (this.clipboardText) {
          offset = this.clipboardText.length + (this.currentCaretPosition || 0)
        }

        if (offset > input.textContent.length) {
          offset = input.textContent.length
        }

        // Create a new range and set the cursor position
        const range: Range = document.createRange()
        range.setStart(input.childNodes[0], offset)
        range.collapse(true)

        // Remove all ranges and add the new one
        const selection: Selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        this.setCaretPosition(selection)
        this.clipboardText = null
      } else {
        const selection: Selection = window.getSelection()
        this.setCaretPosition(selection)
      }

      if (input.textContent === '') {
        input.style.display = 'inline-block'
      }

      console.log('text event', event.target)

      this.editTemplate(
        event?.target?.value || event?.target?.textContent || '',
        input,
        index
      )
    },
    onPasteEvent (event: ClipboardEvent): void {
      const text = event.clipboardData.getData('text/plain')
      if (text) {
        this.clipboardText = text
      }
    },
    onClickEvent (event: MouseEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`
      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onKeyupEvent (event: KeyboardEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`
      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onFocusEvent (input: HTMLSpanElement, event: FocusEvent, index: number): void {
      this.currentInput = `${this.idTemplateInput}Param${index}`

      input.style.display = input.textContent === '' ? 'inline-block' : 'inline'

      const selection: Selection = event.view.getSelection()
      this.setCaretPosition(selection)
    },
    onBlurEvent (input: HTMLSpanElement, _: FocusEvent): void {
      input.style.display = input.textContent === '' ? 'inline-block' : 'inline'
    },
    setCaretPosition (selection: Selection): void {
      if (!selection) return

      if (selection.type === 'Caret') {
        this.currentCaretPosition = selection?.anchorOffset
      }
    },
    editTemplate (text: string, _: HTMLSpanElement, index: number): void {
      this.templateParams[index].value = text
      console.log(text, 'EIDITIN')
      if (text.length === 0) {
        this.handleTemplateEditing()
        return
      }
      this.handleTemplateEditing()
    },
    handleTemplateEditing (): void {
      const baseData: {[key: string]: any} = {
        templateMessage: this.templateMessageUnfilled,
        templateId: this.templateId,
        templateParams: [],
        buttonParams: [],
        mediaParams: [],
        headerParams: [],
        valid: true,
        error: ''
      }

      if (!this.validateTemplateText()) {
        baseData.error = this.$t('allFieldsAreRequired')
        baseData.valid = false
      }

      baseData.templateParams = this.templateParams.map((param: {[key: string]: any}) => this.cleanMessage(param.value))
      baseData.templateMessage = this.templateMessageUnfilled.replace(/\n/g, ' ')

      this.templateVariables = baseData

      this.$emit('input', baseData)
    },
    cleanMessage (text: string): string {
      let newText = text.replace(/\n/g, ' ')
      newText = newText.replace(/  +/g, ' ')
      return newText
    },
    validateTemplateText (): boolean {
      if (this.templateParams?.length === 0) {
        return true
      }

      return this.templateParams.every((param: {[key: string]: any}) => param.value.length > 0)
    }
  },
  beforeDestroy () {
    this.destroyParamsEventListener()
  }
}
