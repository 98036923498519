


































































import { EventBus } from '@/eventBus'
import FacebookService from '@/components/configuration/Services/FacebookService'
import FacebookProvider from '@/components/configuration/Providers/FacebookProvider'
import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBButton from '@/shared/infrastructure/ui/buttons/KBButton.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import FacebookLogin from '@/components/configuration/conversations/channels/FacebookLogin/FacebookLogin.vue'
import { ChannelsEnum } from '@/modules/Config/Channels/shared/domain/ChannelsEnum'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog, ActivitySubmoduleTypeEnum } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import { mapState } from 'vuex'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
const instagramWebhooks = [CDPFieldsEnums.NAME, 'feed']

export default {
  components: { KBButton, FacebookLogin, KBSmallSelector },
  props: ['hasInstagram', 'config', 'tokenPercentage', 'type'],
  data: function () {
    return {
      longLivedAccessToken: '',
      instagramAppId: process.env.VUE_APP_INSTAGRAM_APP_ID,
      instagramPermissions: [
        'public_profile',
        'instagram_basic',
        'instagram_manage_messages',
        'pages_show_list',
        'pages_manage_metadata',
        'pages_read_engagement',
        'business_management',
        'instagram_manage_insights',
        'instagram_manage_comments'
      ],
      pages: [],
      selectedPage: undefined,
      ChannelsEnum,
      activityLogsController: new ActivityLogsController(),
      CDPFieldsEnums
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  methods: {
    async getPages (shortLivedAccessToken: string): Promise<void> {
      try {
        if (this.isUserReadonly) return
        this.longLivedAccessToken = await FacebookService.getLongLivedAccessToken('instagram', shortLivedAccessToken)
        const userAccounts = await FacebookService.getUserAccounts(this.longLivedAccessToken)
        if (!userAccounts?.data.length) {
          throw new Error(this.$t('unexpectedError'))
        }
        const pages = userAccounts.data
        const igBusinessAccounts = await Promise.all(
          pages.map((p) => {
            return FacebookService.getIGBusinessAccount(p.id, this.longLivedAccessToken)
          })
        )
        for (const page of pages) {
          const pageIGBusinessAccount = igBusinessAccounts.find((pba) => pba.id === page.id)
          const igBusinessAccountId = pageIGBusinessAccount?.instagram_business_account?.id
          if (igBusinessAccountId) {
            this.pages.push({
              ...page,
              igBusinessAccountId
            })
          }
        }
        if (!this.pages.length) {
          throw new Error('No es posible realizar la conexión con las páginas seleccionadas')
        }
        this.selectedPage = this.pages[0]
      } catch (error) {
        FacebookProvider.setBusySDK(false)
        console.error('An error has occurred while trying to get pages', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async connect (): Promise<void> {
      try {
        const pageAccessToken = this.selectedPage.access_token
        await FacebookService.subscribeToWebhooks(
          this.selectedPage.id,
          instagramWebhooks,
          pageAccessToken
        )
        try {
          await FacebookService.getIGConversations(this.selectedPage.id, pageAccessToken)
        } catch (error) {
          if (error.message === '(#36103) This IG account is not eligible for API yet.') {
            throw new Error(this.$t('instagramNotEligible'))
          } else {
            throw new Error(this.$t('unexpectedError'))
          }
        }
        try {
          await ConversationsConfigService.createInstagramConfig({
            pageName: this.selectedPage?.name || '',
            pageId: this.selectedPage.id,
            pageAccessToken,
            instagramId: this.selectedPage.igBusinessAccountId
          })
        } catch (error) {
          if (error.message === 'Error creating a Instagram configuration: The Instagram account is already connected to a Keybe App') {
            throw new Error(this.$t('instagramIsAlreadyConnected'))
          } else {
            throw new Error(this.$t('unexpectedError'))
          }
        }
        await FacebookService.logout()
        FacebookProvider.setBusySDK(false)
        this.pages = []
        EventBus.$emit('toast', 'success', this.$t('instagramConnectedSuccessfully'))
        await this.writeActivityLog()
        this.$emit('connectedInstagram')
      } catch (error) {
        console.error('An error has occurred while trying to connect', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.CHANNELS,
          action: ActivityActionTypeEnum.INSTAGRAM_CONNECTED,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async refreshToken (shortLivedAccessToken: string): Promise<void> {
      try {
        const longLivedAccessToken = await FacebookService.getLongLivedAccessToken('instagram', shortLivedAccessToken)
        const userAccounts = await FacebookService.getUserAccounts(longLivedAccessToken)
        if (!userAccounts?.data.length) {
          throw new Error('Ha ocurrido un error, por favor inténtalo más tarde')
        }
        const pages = userAccounts.data
        const instagramId = this.config.instagram_id
        const igBusinessAccounts = await Promise.all(
          pages.map((p) => {
            return FacebookService.getIGBusinessAccount(p.id, longLivedAccessToken)
          })
        )
        if (!igBusinessAccounts.length) {
          throw new Error('No se concedieron los permisos necesarios en la página')
        }

        const igBusinessAccount = igBusinessAccounts.find((pba) => pba?.instagram_business_account?.id === instagramId)
        if (!igBusinessAccount) {
          throw new Error('No se concedieron los permisos necesarios en la página')
        }

        const pageId = igBusinessAccount.id
        const page = pages.find((p) => p.id === pageId)
        const pageAccessToken = page.access_token
        try {
          await ConversationsConfigService.updateInstagramConfig({
            instagramId,
            pageAccessToken,
            pageId
          })
        } catch (error) {
          throw new Error('Ha ocurrido un error, por favor inténtalo más tarde')
        }
        await FacebookService.logout()
        FacebookProvider.setBusySDK(false)
        EventBus.$emit('toast', 'success', 'Conexión extendida satisfactoriamente')
        this.$emit('refreshedToken')
      } catch (error) {
        FacebookProvider.setBusySDK(false)
        console.error('An error has occurred while trying to refresh the token', error)
        EventBus.$emit('toast', 'error', error.message)
      }
    },
    goToPage () {
      window.open(`https://facebook.com/${this.config.page_id}`, '_blank')
    }
  }
}
