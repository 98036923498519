import { mapGetters, mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'
import ProductController from '../../controllers/Product.controller'

// components
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import ProductImages from '@/modules/catalog/infrastructure/ui/ProductImages/ProductImages.vue'
import KeybeMoney from '@/modules/DesignSystem/infrastructure/components/KeybeMoney/KeybeMoney.vue'
import ProductCategories from '@/modules/catalog/infrastructure/ui/ProductCategories/ProductCategories.vue'
import ProductVariationsConfig from '@/modules/catalog/infrastructure/ui/ProductVariationsConfig/ProductVariationsConfig.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import RelatedProducts from '../RelatedProducts/RelatedProducts.vue'
import {
  ProductDTOObject,
  productDTO
} from '@/modules/catalog/domain/models/ProductsController'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import Colors from '@/utils/Colors'
import { Product } from '@/modules/catalog/domain/models/Product'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import EmitToast from '@/utils/EmitToast'
import ProductDiscounts from '../ProductDiscounts/ProductDiscounts.vue'
import ProductVideos from '../ProductVideos/ProductVideos.vue'
export default {
  components: {
    KeybeText,
    KeybeButton,
    KeybeTextarea,
    ProductImages,
    KeybeMoney,
    ProductCategories,
    ProductVariationsConfig,
    KeybeSelect,
    RelatedProducts,
    KeybeIcon,
    ProductDiscounts,
    ProductVideos
  },
  props: {
    categories: {
      type: Array
    },
    selectedCategory: {
      type: Object
    },
    discountTypes: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('AppStore', ['getCurrencyApp']),
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user'])
  },
  data () {
    return {
      selectedCategories: [],
      name: '',
      description: '',
      longDescription: '',
      uploadedImages: [],
      videos: [],
      product: {},
      loadingCreate: false,
      productController: null,
      productVariations: [],
      selectedRelated: [],
      productDiscounts: {},
      price: 0,
      externalUrl: '',
      currency: 'USD',
      currencies: ['USD', 'COP', 'MXN', 'EUR', 'DOP', 'ARS', 'PEN', 'CRC'],
      percentage: [
        'percentage_discounts',
        'quantity_discounts'
      ],
      activityLogsController: new ActivityLogsController(),
      Colors,
      KeybeIconType
    }
  },
  methods: {
    close () {
      this.selectedCategories = []
      this.name = []
      this.description = []
      this.uploadedImages = []
      this.videos = []
      this.product = {}
      this.$emit('closeCreate')
    },
    async createProduct () {
      try {
        if (!this.name) {
          EventBus.$emit('toast', 'error', this.$t('nameRequired'))
          return
        } else if (this.uploadedImages.length === 0) {
          EventBus.$emit('toast', 'error', this.$t('mustAddAtLeast1Picture'))
          return
        }

        if (this.videos.length) {
          this.videos = this.videos.filter((video: { url: string, description: string }) => video.url)
        }
        this.loadingCreate = true

        const data: ProductDTOObject = {
          appUUID: this.selectedApp?.uuid,
          category: this.selectedCategories,
          product: this.name,
          checkColor: false,
          colors: [],
          description: this.description,
          images: this.uploadedImages,
          currency: this.currency,
          externalUrl: this.externalUrl,
          videos: this.videos,
          price: 0
        }

        if (Object.keys(this.productDiscounts).length > 0) {
          data.discountPrices = this.productDiscounts
        }

        if (this.selectedRelated.length > 0) {
          data.relatedProducts = this.selectedRelated.map((product: Product) => ({
            sku: product.sku,
            product: product.product,
            price: product.price,
            currency: product.currency,
            id: product.id
          })
          )
        }

        if (typeof this.price === 'string') {
          data.price = parseFloat(this.price)
        } else {
          data.price = this.price
        }

        const dataToCreate: productDTO = {
          product: data,
          token: this.token
        }
        const product: Product = await ProductUseCases.createProduct(
          this.productController,
          dataToCreate
        )
        if (this.longDescription) {
          const response = await ProductUseCases.generateLongDescription(this.productController, {
            token: this.token,
            longDescription: this.longDescription,
            appId: this.selectedApp?.uuid || '',
            productId: product.id
          })

          if (!response.status) {
            EmitToast.emitErrors(response.message)
          }
        }

        await this.writeActivityLog()

        // eslint-disable-next-line
        this.product = structuredClone(product)

        EventBus.$emit('createVariations', product)

        this.$emit('productCreated')
        this.loadingCreate = false
      } catch (e) {
        console.error(e)
        EventBus.$emit('toast', 'error', e)
        this.loadingCreate = false
      }
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          action: ActivityActionTypeEnum.CATALOG_PRODUCT_CREATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.NAME,
            newValue: this.product.product,
            oldValue: ''
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }
  },
  created () {
    this.productController = new ProductController()
  },
  mounted () {
    this.currency = this.getCurrencyApp
  },
  beforeDestroy () {
    this.productController = null
  }
}
