import {
  IProductController,
  dataToChangeProductStatus,
  dataToDeleteProduct,
  dataToGetProducts,
  productDTO,
  dataToSyncWithShopify, SyncWithShopifyGetter, dataToGetProductVariations,
  dataToGenerateLongDescription,
  discountTypesGetter
} from '../domain/models/ProductsController'

export default class ProductUseCases {
  static async getProducts (controller: IProductController, data: dataToGetProducts) {
    return await controller.getProducts(data)
  }

  static async createProduct (controller: IProductController, data: productDTO) {
    return await controller.createProduct(data)
  }

  static async updateProduct (controller: IProductController, data: productDTO) {
    return await controller.updateProduct(data)
  }

  static async deleteProduct (controller: IProductController, data: dataToDeleteProduct) {
    return await controller.deleteProduct(data)
  }

  static async changeProductStatus (controller: IProductController, data: dataToChangeProductStatus) {
    return await controller.changeProductStatus(data)
  }

  static async syncWithShopify (controller: IProductController, data: dataToSyncWithShopify): Promise<SyncWithShopifyGetter> {
    return await controller.syncWithShopify(data)
  }

  static async getProductVariations (controller: IProductController, data: dataToGetProductVariations) {
    return await controller.getProductVariations(data)
  }

  static abortAll (controller: IProductController): void {
    return controller.abortAll()
  }

  static generateLongDescription (controller: IProductController, data: dataToGenerateLongDescription): Promise<SyncWithShopifyGetter> {
    return controller.generateLongDescription(data)
  }

  static getDiscountTypes (controller: IProductController, token: string): Promise<discountTypesGetter> {
    return controller.getDiscountTypes(token)
  }
}
