import { IPartnersController, dataToGetCommissions, partnerBrandsGetter } from '../../domain/PartnersController'
import PartnersRepository from '../repository/Partners.repository'

export default class PartnersController implements IPartnersController {
  repository: PartnersRepository

  constructor () {
    this.repository = new PartnersRepository()
  }

  async getCommissions (data: dataToGetCommissions) : Promise<partnerBrandsGetter> {
    const response = await this.repository.getCommissions(data)
    if (response.status) return { data: response.data, status: true }
    return { status: false, message: response.message }
  }
}
