import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { dataToGetCommissions } from '../../domain/PartnersController'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { getOptions } from '@/shared/infrastructure/http/HttpClient'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'

export default class PartnersRepository extends KeybeRepository {
  getCommissionsAbortController: AbortController

  constructor () {
    super()

    this.getCommissionsAbortController = new AbortController()
  }

  abortGetCommissions () {
    this.getCommissionsAbortController.abort()
    this.getCommissionsAbortController = new AbortController()
  }

  async getCommissions (data: dataToGetCommissions): Promise<GenericResponse> {
    const { email } = data
    const url = `${process.env.VUE_APP_FEES_URL}/fees/commissions`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: getOptions = {
      headers,
      url,
      query: {
        partner: email
      },
      abortController: this.getCommissionsAbortController
    }

    return await this.client.get(requestOptions)
  }
}
