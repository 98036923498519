

















































import { ConsumptionsTabs } from '@/modules/consumptions/infrastructure/enums/ConsumptionsTabs'
import moment from 'moment'
// TODO: migrate to new structure
import ConsumptionService from '@/components/configuration/Services/ConsumptionService'
import BillingService from '@/components/configuration/Services/BillingService'
import { PaymentService } from '@/components/configuration/Services/PaymentService'
import { EventBus } from '@/eventBus'
import { DateTime } from 'luxon'

import ConsumptionsSidebar from '@/modules/consumptions/infrastructure/ui/components/ConsumptionsSidebar/ConsumptionsSidebar.vue'
import ConsumptionsDetails from '@/modules/consumptions/infrastructure/ui/components/ConsumptionsDetails/ConsumptionsDetails.vue'
import InvoicesDetails from '@/modules/consumptions/infrastructure/ui/components/InvoicesDetails/InvoicesDetails.vue'
import BillingUsage from '@/modules/consumptions/infrastructure/ui/components/BillingUsage/BillingUsage.vue'
import PaymentMethods from '@/modules/consumptions/infrastructure/ui/components/PaymentMethods.vue'
import AddBalance from '@/modules/consumptions/infrastructure/ui/components/AddBalance/AddBalance.vue'
import BillingPlansView from '@/modules/consumptions/infrastructure/ui/components/BillingPlansView/BillingPlansView.vue'
import BillingPackages from '@/modules/consumptions/infrastructure/ui/components/BillingPackages/BillingPackages.vue'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
// import BillingCommissions from '@/modules/consumptions/infrastructure/ui/components/BillingCommissions/BillingCommissions.vue'
import BillingAddPayment from '@/modules/consumptions/infrastructure/ui/components/BillingAddPayment/BillingAddPayment.vue'
import { mapState } from 'vuex'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import KBInvoicesBrands from '@/modules/consumptions/infrastructure/ui/components/KBInvoices/KBInvoicesBrands/KBInvoicesBrands.vue'
import KBInvoicesServices from '@/modules/consumptions/infrastructure/ui/components/KBInvoices/KBInvoicesServices/KBInvoicesServices.vue'
import KBInvoicesList from './components/KBInvoices/KBInvoicesList/KBInvoicesList.vue'
import KBInvoicesPartners from '@/modules/consumptions/infrastructure/ui/components/KBInvoices/KBInvoicesPartners/KBInvoicesPartners.vue'
import BillingCommissionsByUser from './components/BillingCommissionsByUser/BillingCommissionsByUser.vue'
import KBPlansList from './components/KBInvoices/KBPlansList/KBPlansList.vue'
import KBDailyContactList from './components/KBInvoices/KBDailyContactList/KBDailyContactList.vue'
import KBProductsList from './components/KBInvoices/KBProductsList/KBProductsList.vue'
const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_DATE2 = 'yyyy-MM-dd' // Corrección del formato

export default {
  name: 'ConsumptionsScreen',
  props: {
    noBalance: {
      type: Boolean,
      default: false
    },
    addAccountBalance: {
      type: Boolean,
      default: false
    },
    changePlan: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConsumptionsSidebar,
    ConsumptionsDetails,
    Loader,
    InvoicesDetails,
    AddBalance,
    BillingUsage,
    PaymentMethods,
    BillingAddPayment,
    BillingPlansView,
    // BillingCommissions,
    BillingCommissionsByUser,
    BillingPackages,
    KBInvoicesBrands,
    KBInvoicesServices,
    KBInvoicesList,
    KBInvoicesPartners
  },
  data () {
    return {
      dataBilling: null,
      ConsumptionsTabs,
      selectedTab: ConsumptionsTabs.CONSUMPTIONS,
      loading: false,
      groups: [],
      labels: [],
      consumptions: [],
      invoices: [],
      totalConsumption: 0,
      dateString: '',
      creditCards: [],
      treeMapData: [],
      addCreditCard: false,
      addBalance: true,
      dateFrom: null,
      dateTo: null,
      groupsString: []
    }
  },
  watch: {
    changeOption () {
      if (this.changeOption) {
        this.selectedTab = this.changeOption
      }
    },
    changePlan: {
      handler (value) {
        if (value) {
          this.selectedTab = ConsumptionsTabs.PLANS
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('BillingStore', ['appConfig']),
    ...mapState('AppStore', ['selectedApp']),
    inactiveApp (): boolean {
      const status = this.selectedApp?.status
      return !(status === 'active' || status == null || status === true || status === false || status === 200)
    },
    generalDueDate () {
      const { dueDate } = this.dataBilling
      const nuevaFecha = DateTime.fromISO(dueDate)
      return nuevaFecha.toLocaleString(DateTime.DATE_HUGE)
    },
    changeOption: {
      get () {
        return this.$store.state.BillingStore.changeOption || null
      },
      set (value) {
        this.$store.commit('BillingStore/SET_EDITING_OBJECT', {
          key: 'changeOption',
          value
        })
      }
    },
    selectedTabComponent () {
      // [ConsumptionsTabs.PAYMENTS_METHODS]: PaymentMethods,

      const components = {
        [ConsumptionsTabs.DETAILS]: ConsumptionsDetails,
        [ConsumptionsTabs.INVOICES]: InvoicesDetails,
        [ConsumptionsTabs.CONSUMPTIONS]: BillingUsage,
        [ConsumptionsTabs.PLANS]: BillingPlansView,
        [ConsumptionsTabs.PAYMENTS_METHODS]: AddBalance,
        [ConsumptionsTabs.ADD_PAYMENT]: BillingAddPayment,
        [ConsumptionsTabs.COMMISSIONS]: BillingCommissionsByUser,
        [ConsumptionsTabs.PACKAGES]: BillingPackages,
        [ConsumptionsTabs.BRANDS]: KBInvoicesBrands,
        [ConsumptionsTabs.SERVICES]: KBInvoicesServices,
        [ConsumptionsTabs.NEW_INVOICES]: KBInvoicesList,
        [ConsumptionsTabs.PARTNERS]: KBInvoicesPartners,
        [ConsumptionsTabs.CONFIG_PLANS]: KBPlansList,
        [ConsumptionsTabs.DAILY_CONTACT]: KBDailyContactList,
        [ConsumptionsTabs.PRODUCTS]: KBProductsList
      }

      return components[this.selectedTab]
    },
    optionBarChart () {
      const groupsBar = this.groupsString
      const series = []

      // Controlar estos valores
      if (this.consumptions.length < 1) {
        return {
          tooltip: {},
          xAxis: {},
          legend: {},
          yAxis: {},
          dataZoom: {},
          series: []
        }
      }
      const dateRange = this.enumerateDays(this.dateFrom, this.dateTo)
      const totalDays = dateRange.length

      for (const name of groupsBar) {
        const dayConsumptions = new Array(totalDays).fill(0, 0, totalDays)
        let appendElement = false
        for (const dailyConsumption of this.consumptions) {
          const deltaTime = dailyConsumption.deltaTime

          if (dailyConsumption.group === name) {
            const dayPosition = dateRange.findIndex(date => date === deltaTime)
            dayConsumptions[dayPosition] = dayConsumptions[dayPosition] + dailyConsumption.count
            appendElement = true
          }
        }

        if (appendElement) {
          series.push({
            name: this.$t(name),
            data: dayConsumptions
          })
        }
      }
      return {
        xAxis: {
          type: 'day',
          data: dateRange
        },
        series
      }
    }
  },
  methods: {
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    },
    formatCounter (value) {
      return (!value) ? 0 : parseFloat(value).toFixed(2)
    },
    // TODO: refactor to remove burned strings
    isUserLicense (type) {
      return type === 'user_license'
    },
    isMessageSms (type) {
      return type.includes('message_sms_')
    },
    isBussinesWhat (type) {
      return type.includes('wa_session_business_')
    },
    isUserWhat (type) {
      return type.includes('wa_session_user_')
    },
    isUtilitySessions (type) {
      if (type.includes('wa_session_utility_bussiness_') || type.includes('wa_session_utility_business_')) {
        return true
      } else {
        return false
      }
    },
    async getLabelsModel () {
      const naming = await BillingService.getLabelsNaming()
      if (naming.status) {
        this.labels = naming.data
      }
    },
    isPlan (type) {
      return type.includes(CDPFieldsEnums.PLAN)
    },
    isDailyContacts (type) {
      return type.includes('daily_contact')
    },
    isOther (type) {
      return type.includes('wa_session_marketing_')
    },
    labelingChartTypes (data) {
      const groupsModel = new Set()
      const model = data.map(service => {
        const chargeType = this.isMessageSms(service.chargeType)
          ? 'message_sms_col'
          : this.isBussinesWhat(service.chargeType)
            ? 'wa_session_business_col'
            : this.isUserWhat(service.chargeType)
              ? 'wa_session_user_col'
              : this.isOther(service.chargeType)
                ? 'wa_session_marketing_'
                : this.isUtilitySessions(service.chargeType)
                  ? 'wa_session_utility_business'
                  : this.isDailyContacts(service.chargeType)
                    ? 'daily_contact'
                    : this.isPlan(service.chargeType)
                      ? 'plans'
                      : service.chargeType
        const priceUnit = service.priceUnit
        service.discount = service.discount > 50 ? 0 : service.discount
        const priceSubTotal = service.priceTotal // priceUnit * service.count
        let priceTotal = service.priceTotal // priceSubTotal * (1 - (service.discount / 100))
        if (this.isUserLicense(chargeType)) {
          const value = service.priceTotal // priceSubTotal - (priceUnit * service.discount)
          priceTotal = value < 0 ? 0 : value
        }
        this.totalConsumption += priceTotal

        // add data for treemap
        const index = this.treeMapData.findIndex(item => item.x === this.$t(chargeType))
        if (index !== -1) {
          this.treeMapData[index].y += priceTotal
        } else {
          this.treeMapData.push({
            x: this.$t(chargeType),
            y: priceTotal
          })
        }

        groupsModel.add(chargeType)
        return {
          ...service,
          chargeType: this.labels[service.chargeType] || service.chargeType,
          count: parseFloat(service.count.toFixed(2)),
          priceSubTotal: parseFloat(priceSubTotal.toFixed(4)),
          priceUnit: parseFloat(priceUnit.toFixed(4)),
          priceTotal: parseFloat(priceTotal.toFixed(4)),
          group: chargeType,
          name: this.$t(chargeType)
        }
      })
      this.groups = groupsModel.values()
      const groupsString = []
      for (const group of this.groups) {
        groupsString.push(group)
      }
      this.groupsString = groupsString
      return model
    },
    async getConsumptions (dateRange) {
      try {
        const response = await ConsumptionService.getConsumption(dateRange)

        if (!response.status) {
          // TODO: Handle error
          return
        }
        const data = response?.data || []
        if (data.length > 0) {
          this.consumptions = this.labelingChartTypes(data)
        } else {
          this.treeMapData = []
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async getInvoices () {
      const invoices = await BillingService.getInvoices()
      const processedInvoices = []
      for (const invoice of invoices?.data?.data) {
        const proccesedInvoiceDetails = invoice.invoiceDetails.map(details => {
          return {
            ...details,
            type: details.chargeType.type,
            description: details.chargeType.description,
            subTotal: this.formatCurrency(details.amountSubTotal, 'USD'),
            total: this.formatCurrency(details.amountTotal, 'USD')
          }
        })
        processedInvoices.push({
          ...invoice,
          invoiceDetails: proccesedInvoiceDetails
        })
      }
      this.invoices = processedInvoices
    },
    async handleDateChange (data) {
      try {
        const { dateString, dateFrom, dateTo } = data
        this.dateFrom = dateFrom
        this.dateTo = dateTo
        this.dateString = dateString
        this.loading = true
        await this.getConsumptions({ dateFrom, dateTo })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async getCreditCards () {
      try {
        const response = await PaymentService.getCreditCards()
        if (response.status) {
          if (response.data) {
            this.creditCards = response.data.map((card) => ({
              id: card.id,
              tokenResponse: {
                brand: card.card.brand,
                last4: card.card.last4
              }
            }))
          }

        // const cards = response.data.paymentCards.rows
        // this.creditCards = cards.map((card, i) => {
        //   card.main = i === 0
        //   return card
        // })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async refreshCards () {
      try {
        this.$emit('start-loading')
        await this.getCreditCards()
        this.$emit('stop-loading')
        this.addCreditCard = false
      } catch (error) {
        this.$emit('stop-loading')
      }
    },
    enumerateDays (startDate, endDate = new Date()) {
      const dates = []
      const currDate = moment(startDate).subtract(1, 'day')
      const lastDate = moment(endDate).add(1, 'day')

      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format(FORMAT_DATE))
      }
      return dates
    },
    succcessAddBalance () {
      try {
        this.$emit('start-loading')
        this.addBalance = false
        this.handleDateChange({ dateString: this.dateString, dateFrom: this.dateFrom, dateTo: this.dateTo })
        this.$emit('stop-loading')
      } catch (error) {
        this.$emit('stop-loading')
      }
    },
    async getPackage () {
      try {
        const invoices = await BillingService.getPackage()
        if (invoices?.data?.statusCode === 404) {
          this.dataBilling = null
          return
        }
        if (invoices) {
          this.dataBilling = invoices.data
          const { dueDate } = this.dataBilling
          const generalDueDate = DateTime.fromISO(dueDate)
          // Calcular dateFrom: Añadir 1 día y restar 1 mes
          const dateFrom = generalDueDate.plus({ days: 1 }).minus({ months: 1 }).toFormat(FORMAT_DATE2)
          // Calcular dateTo: La fecha actual
          const dateTo = DateTime.now().toFormat(FORMAT_DATE2)
          // Pasar las fechas calculadas a una función para manejarlas
          this.handleDateChange({ dateString: this.$t('custom'), dateFrom, dateTo })
        }
      } catch (error) {
        this.dataBilling = null
        console.error(error)
      }
    }
  },
  mounted () {
    EventBus.$on('setConsumptionsTab', (tab) => {
      this.selectedTab = tab
    })
  },
  async created () {
    if (this.inactiveApp) {
      this.selectedTab = ConsumptionsTabs.PAYMENTS_METHODS
    }

    EventBus.$on('addCreditCard', () => {
      this.addCreditCard = true
    })

    EventBus.$on('addBalance', () => {
      this.addBalance = true
    })
    try {
      await this.getLabelsModel()
      this.$emit('start-loading')
      this.getPackage()
      // this.dateString = this.$t('thisMonth')

      await this.getInvoices()
      await this.getCreditCards()
      this.$emit('stop-loading')
    } catch (error) {
      this.$emit('stop-loading')
    }

    if (this.noBalance) {
      this.addBalance = true
    }

    if (this.addAccountBalance) {
      this.addBalance = true
    }
  },
  beforeDestroy () {
    // window.removeEventListener('resize', this.calculateSize)
    EventBus.$off('setConsumptionsTab')
    EventBus.$off('stop-loading')
    EventBus.$off('start-loading')
    EventBus.$off('addCreditCard')
    EventBus.$off('addBalance')
  }
}
