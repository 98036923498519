import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import { EventBus } from '@/eventBus'
import { debounce } from 'lodash'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import Colors from '@/utils/Colors'
import { mapState } from 'vuex'
export default {
  name: 'AutomaticClosingConfig',
  components: {
    KBText,
    ModalWarning,
    KeybeButton,
    KeybeIcon
  },
  props: {
    inbox: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      minMinutes: 1,
      maxMinutes: 1440,
      showWarning: false,
      messageWarning: '',
      localInbox: {},
      activityLogsController: new ActivityLogsController(),
      afterMinutes: 0,
      Colors,
      KeybeIconType
    }
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  mounted () {
    this.setInbox(this.inbox)
    EventBus.$on('inboxUpdated--spaces', this.setInbox.bind(this))
    this.getChatConfig()
  },
  methods: {
    setInbox (inbox: any): void {
      // eslint-disable-next-line no-undef
      this.localInbox = structuredClone(inbox)
    },
    inboxAfterMinutes () {
      return this.localInbox?.autoClose?.afterMinutes || 0
    },
    setInboxAfterMinutes (value: number): void {
      if (!this.localInbox.autoClose) this.localInbox.autoClose = {}
      this.localInbox.autoClose.afterMinutes = value
    },
    getChatConfig ():void {
      if (this.localInbox?.autoClose?.afterMinutes === undefined) {
        this.showWarning = true
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('reassign') })
      } else {
        this.showWarning = false
        this.messageWarning = ''
        this.afterMinutes = this.localInbox?.autoClose?.afterMinutes
        if (this.localInbox?.autoClose?.afterMinutes > this.maxMinutes) {
          setTimeout(() => {
            this.localInbox.autoClose.afterMinutes = this.maxMinutes
            this.saveConfig()
          }, 1000)
        }
      }
    },
    writeActivityLog: debounce(async function (): Promise<void> {
      let newValue = ''
      let oldValue = ''
      if (this.localInbox.reassignForAdviserInactiveMinutes !== this.inbox.reassignForAdviserInactiveMinutes) {
        newValue = this.localInbox?.reassignForAdviserInactiveMinutes || ''
        oldValue = this.inbox?.reassignForAdviserInactiveMinutes || ''
      }

      if (this.localInbox.reassignForUserInactiveMinutesWithBot !== this.inbox.reassignForUserInactiveMinutesWithBot) {
        newValue = this.localInbox?.reassignForUserInactiveMinutesWithBot || ''
        oldValue = this.inbox?.reassignForUserInactiveMinutesWithBot || ''
      }

      if (this.localInbox.autoClose.afterMinutes !== this.inbox.autoClose.afterMinutes) {
        newValue = this.localInbox?.autoClose?.afterMinutes || ''
        oldValue = this.inbox?.autoClose?.afterMinutes || ''
      }

      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE,
          object: this.inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.CLOSING_CONFIG,
            newValue: newValue.toString(),
            oldValue: oldValue.toString()
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    }, 500),
    saveConfig: debounce(async function (): Promise<void> {
      if (this.isUserReadonly) return
      if (this.afterMinutes < this.minMinutes) {
        EventBus.$emit('toast', 'success', this.$t('minTimeAutoClose'))
        return
      }
      if (this.afterMinutes > this.maxMinutes) {
        this.afterMinutes = this.maxMinutes
      }
      await this.writeActivityLog()

      this.$emit('inbox-updated', {
        autoClose: this.localInbox?.autoClose,
        reassignForAdviserInactiveMinutes: this.localInbox?.reassignForAdviserInactiveMinutes,
        reassignForUserInactiveMinutesWithBot: this.localInbox?.reassignForUserInactiveMinutesWithBot
      })
      EventBus.$emit('inboxUpdated--spaces', this.localInbox)
    }, 500),
    closeModal (): void {
      this.showWarning = false
      this.messageWarning = ''
    }
  }
}
