import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/shared/infrastructure/http/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { getOptions, nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToDisconnectChannel, dataTogGetWhatsAppBusinessProfile, dataToUpdateWhatsAppBusinessProfile, dataToUpdateWhatsAppBusinessProfilePhoto } from '../../domain/ChannelsController'

export default class ChannelsRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async disconnectChannel (data: dataToDisconnectChannel): Promise<GenericResponse> {
    const { appUUID, type, id } = data

    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/channels/app/${appUUID}/${type}/${id}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.delete(requestOptions)
  }

  async getWhatsAppBusinessProfile (data: dataTogGetWhatsAppBusinessProfile): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_FACEBOOK_GRAPH_URL}/${data.phoneNumberId}/whatsapp_business_profile?fields=${data.fields}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const requestOptions: getOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.get(requestOptions)
  }

  async updateWhatsAppBusinessProfile (data: dataToUpdateWhatsAppBusinessProfile): Promise<GenericResponse> {
    const { updateInput, userHost, appId } = data
    const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/whatsapp/${appId}/phoneInfo/${userHost}`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: updateInput,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.put(requestOptions)
  }

  async updateWhatsAppBusinessProfilePhoto (data: dataToUpdateWhatsAppBusinessProfilePhoto): Promise<GenericResponse> {
    const { img, userHost, appId } = data
    const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/config/whatsapp/${appId}/phoneInfo/${userHost}`
    const headers = HeadersBuilder.buildFormDataAutorizationHeader(data)

    const fd = new FormData()
    if (img) fd.append('file', img)

    const requestOptions: nonGetOptions = {
      headers,
      url,
      data: fd,
      abortController: this.getSuggestionsAbortController,
      isFormData: true
    }

    return await this.client.put(requestOptions)
  }
}
