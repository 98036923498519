import { mapState } from 'vuex'
import { EventBus } from '@/eventBus'
import { Product, ProductImage } from '@/modules/catalog/domain/models/Product'
import ProductController from '../../controllers/Product.controller'
import ProductUseCases from '@/modules/catalog/application/ProductUseCases'

// components
import KeybeText from '@/modules/DesignSystem/infrastructure/components/KeybeText/KeybeText.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import KeybeTextarea from '@/modules/DesignSystem/infrastructure/components/KeybeTextarea/KeybeTextarea.vue'
import ProductImages from '@/modules/catalog/infrastructure/ui/ProductImages/ProductImages.vue'
import ProductCategories from '@/modules/catalog/infrastructure/ui/ProductCategories/ProductCategories.vue'
import ProductVariationsConfig from '@/modules/catalog/infrastructure/ui/ProductVariationsConfig/ProductVariationsConfig.vue'
import KeybeSelect from '@/modules/DesignSystem/infrastructure/components/KeybeSelect/KeybeSelect/KeybeSelect.vue'
import KeybeMoney from '@/modules/DesignSystem/infrastructure/components/KeybeMoney/KeybeMoney.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { dataToGetProductVariations, ProductDTOObject } from '@/modules/catalog/domain/models/ProductsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import Colors from '@/utils/Colors'
import RelatedProducts from '../RelatedProducts/RelatedProducts.vue'
import EmitToast from '@/utils/EmitToast'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import ProductDiscounts from '../ProductDiscounts/ProductDiscounts.vue'
import ProductVideos from '../ProductVideos/ProductVideos.vue'
export default {
  name: 'CatalogUpdateProduct',
  components: {
    KeybeText,
    KeybeButton,
    KeybeTextarea,
    ProductImages,
    ProductCategories,
    KeybeMoney,
    KeybeSelect,
    ProductVariationsConfig,
    RelatedProducts,
    KeybeIcon,
    ProductDiscounts,
    ProductVideos
  },
  props: {
    categories: {
      type: Array
    },
    selectedCategory: {
      type: Object
    },
    product: {
      type: Object,
      required: true
    },
    discountTypes: {
      type: Array
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user'])
  },
  data () {
    return {
      Colors,
      selectedCategories: [],
      selectedRelated: [],
      productData: {},
      productDiscounts: {},
      name: '',
      description: '',
      longDescription: '',
      longDescriptionUrl: '',
      uploadedImages: [],
      videos: [],
      loadingCreate: false,
      loadingVariations: true,
      loadingInitial: false,
      productVariations: [],
      productController: null,
      externalUrl: '',
      price: 0,
      currency: 'USD',
      currencies: [
        'USD',
        'COP',
        'MXN',
        'EUR',
        'DOP',
        'ARS',
        'PEN',
        'CRC'
      ],
      CDPFieldsEnums,
      KeybeIconType,
      activityLogsController: new ActivityLogsController()
    }
  },
  async created () {
    this.loadingInitial = true
    // eslint-disable-next-line no-undef
    this.productData = structuredClone(this.product)
    this.longDescriptionUrl = this.productData?.longDescription || ''
    this.productController = new ProductController()
    await this.getProductVariations()
    await this.getProductRelated()
    this.loadingInitial = false
  },
  mounted () {
  },
  beforeDestroy () {
    ProductUseCases.abortAll(this.productController)
    this.productController = null
  },
  methods: {
    getProductRelated (): void {
      this.selectedRelated = this.productData?.relatedProducts || []
    },
    close () {
      this.selectedCategories = []
      this.name = []
      this.description = []
      this.uploadedImages = []

      this.$emit('closeCreate')
    },
    validateVariations () {
      // eslint-disable-next-line no-undef
      this.productData = structuredClone(this.product)

      if (this.productData?.option1) {
        this.productVariations.push(this.productData?.option1)
      }

      if (this.productData?.option2) {
        this.productVariations.push(this.productData?.option2)
      }

      if (this.productData?.option3) {
        this.productVariations.push(this.productData?.option3)
      }
    },
    async getProductVariations () {
      try {
        const data: dataToGetProductVariations = {
          token: this.token,
          appUUID: this.selectedApp?.uuid || '',
          productId: this.productData.id
        }
        const product: Product = await ProductUseCases.getProductVariations(this.productController, data)
        this.productData = {
          ...this.productData,
          option1: product.option1,
          option2: product.option2,
          option3: product.option3
        }

        if (product.option1?.label) {
          this.productVariations.push(product.option1)
        }
        if (product.option2?.label) {
          this.productVariations.push(product.option2)
        }
        if (product.option3?.label) {
          this.productVariations.push(product.option3)
        }
      } catch (e) {
        EventBus.$emit('toast', 'error', e)
      } finally {
        this.loadingVariations = false
      }
    },
    async updateProduct () {
      try {
        if (!this.name) {
          EventBus.$emit('toast', 'error', this.$t('nameRequired'))
          return
        } else if (this.uploadedImages.length === 0) {
          EventBus.$emit('toast', 'error', this.$t('mustAddAtLeast1Picture'))
          return
        }

        if (this.videos.length) {
          this.videos = this.videos.filter((video: { url: string, description: string }) => video.url)
        }

        this.loadingCreate = true

        const data: ProductDTOObject = {
          // eslint-disable-next-line no-undef
          ...(structuredClone(this.productData) ?? {}),
          option1: undefined,
          option2: undefined,
          option3: undefined,
          appUUID: this.selectedApp?.uuid || '',
          category: this.selectedCategories,
          product: this.name,
          checkColor: false,
          colors: [],
          description: this.description,
          images: this.uploadedImages,
          videos: this.videos,
          currency: this.currency,
          externalUrl: this.externalUrl
        }

        if (Object.keys(this.productDiscounts).length > 0) {
          data.discountPrices = this.productDiscounts
        }

        if (this.selectedRelated.length > 0) {
          data.relatedProducts = this.selectedRelated.map((product: Product) => ({
            sku: product.sku,
            product: product.product,
            price: product.price,
            currency: product.currency,
            id: product.id
          })
          )
        } else data.relatedProducts = []

        if (typeof this.price === 'string') {
          data.price = parseFloat(this.price)
        } else {
          data.price = this.price
        }

        delete data.createdAt

        const product: Product = await ProductUseCases.updateProduct(this.productController, {
          token: this.token,
          product: data
        })

        if (this.longDescription) {
          const response = await ProductUseCases.generateLongDescription(this.productController, {
            token: this.token,
            longDescription: this.longDescription,
            appId: this.selectedApp?.uuid || '',
            productId: product.id
          })

          if (!response.status) {
            EmitToast.emitErrors(response.message)
          }
        }

        await this.writeActivityLog(data)

        // this.product = product
        this.productData = product

        EventBus.$emit('updateVariations')
        this.$emit('productUpdated')
        this.loadingCreate = false
      } catch (e) {
        console.error(e)
        EventBus.$emit('toast', 'error', e)
        this.loadingCreate = false
      }
    },
    async writeActivityLog (updateData: Product): Promise<void> {
      const keys: string[] = [
        'category',
        'product',
        'description',
        'images',
        'currency',
        'externalUrl'
      ]
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        let changed = false
        let newValue = ''
        let oldValue = ''
        if (key === 'category') {
          changed = this.product[key].toString() !== updateData[key].toString()
          newValue = updateData[key].toString()
          oldValue = this.product[key].toString()
        } else if (key === 'images') {
          changed = this.product[key].toString() !== updateData[key].toString()
          newValue = this.uploadedImages.map((image: { url: string, description: string }) => image.url).toString()
          oldValue = this.product[key].map((image: { url: string, description: string }) => image.url).toString()
        } else {
          changed = this.product[key] !== updateData[key]
          newValue = updateData[key]
          oldValue = this.product[key]
        }

        if (
          changed
        ) {
          const data: dataToWriteActivityLog = {
            token: this.token,
            updateInput: {
              appUUID: this.selectedApp?.uuid,
              module: ActivityLogModuleTypeEnum.CHAT,
              action: ActivityActionTypeEnum.CATALOG_PRODUCT_UPDATE,
              user: {
                id: this.user.uuid,
                name: this.user.name + ' ' + this.user.lastName
              },
              payload: {
                field: key,
                newValue: newValue,
                oldValue: oldValue
              }
            }
          }
          await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
        }
      }
    }
  },
  watch: {
    product: {
      immediate: true,
      handler (val: Product) {
        // eslint-disable-next-line no-undef
        this.selectedCategories = structuredClone(val?.category) || []
        // eslint-disable-next-line no-undef
        this.name = structuredClone(val?.product)
        // eslint-disable-next-line no-undef
        this.description = structuredClone(val?.description)
        // eslint-disable-next-line no-undef
        this.uploadedImages = structuredClone(val?.images?.map((image: ProductImage | string) => {
          if (typeof image === 'string') {
            return {
              url: image
            }
          } else {
            return {
              ...image
            }
          }
        }))
        // eslint-disable-next-line no-undef
        this.videos = structuredClone(val?.videos) || []
        this.validateVariations()
        // eslint-disable-next-line no-undef
        this.price = structuredClone(val?.price) || 0
        // eslint-disable-next-line no-undef
        this.externalUrl = structuredClone(val?.externalUrl) || ''
        // eslint-disable-next-line no-undef
        this.currency = structuredClone(val?.currency) || 'USD'
      }
    }
  }
}
