import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
// import LocationUseCases from '@/shared/infrastructure/ui/details/sections/Location/application/LocationUseCases'
import LocationController from '@/shared/infrastructure/ui/details/sections/Location/infrastructure/controllers/Location.controller'
import { SpaceConfig, DayConfig } from '@/modules/Spaces/domain/Spaces'
// TODO: migrate to new structure
// import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'

import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import moment from 'moment/moment'
// import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import KBCheck from '@/shared/infrastructure/ui/inputs/select/KBCheck.vue'
import KBSmallSelector from '@/shared/infrastructure/ui/inputs/select/KBSmallSelector.vue'
import KBTimeSelector from '@/shared/infrastructure/ui/pickers/KBTimeSelector.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBTextarea from '@/shared/infrastructure/ui/inputs/text/KBTextarea.vue'
import KBText from '@/shared/infrastructure/ui/inputs/text/KBText.vue'
import KeybeTime from '@/modules/DesignSystem/infrastructure/components/KeybeTime/KeybeTime.vue'
// import SpacesUseCases from '@/modules/Spaces/application/SpacesUseCases'
import SpacesController from '@/modules/Spaces/infrastructure/Spaces.controller'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import { mapState } from 'vuex'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
// import InboxConfig from '@/modules/inbox/infrastructure/ui/InboxConfig/InboxConfig'

const defaultWorkingHours = {
  days: [
    {
      code: 0,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 1,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 2,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 3,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 4,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 5,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    },
    {
      code: 6,
      isClosed: false,
      isOpen: true,
      schedules: [
        {
          from: '00:00',
          until: '00:00'
        }
      ]
    }
  ]
}

export default {
  name: 'SpacesWorkingHours',
  components: {
    KBSmallSelector,
    KBCheck,
    KBTimeSelector,
    KeybeIcon,
    KBTextarea,
    KBText,
    ModalWarning,
    KeybeTime,
    KeybeButton
  },
  props: {
    inbox: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      timeZones: [],
      timeZone: { label: 'America/Bogota', offset: '−05:00', title: 'America/Bogota GMT-05:00' },
      rerender: 0,
      chatConfig: null,
      daysLabels: [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')],
      workingDays: [],
      KeybeIconType,
      outOfServiceMessage: '',
      inactivityMessage: '',
      awaitingInactivityMessage: false,
      awaitingOutOfServiceMessage: false,
      inactivitySeconds: '0',
      loading: false,
      SpacesController: new SpacesController(),
      locationController: new LocationController(),
      activityLogsController: new ActivityLogsController(),
      inboxList: [],
      allInboxes: false,
      useInboxConfig: false,
      inboxName: '',
      inboxModel: {},
      localInbox: {},
      showWarning: false,
      messageWarning: '',
      KeybeIcon,
      Colors
    }
  },
  async mounted () {
    this.setInbox(this.inbox)
    EventBus.$on('inboxUpdated--spaces', this.setInbox.bind(this))
    this.getChatConfig()
    // await this.getTimeZones()
    this.setInboxConfig()
  },
  computed: {
    ...mapState('UserStore', ['user', 'token', 'isUserReadonly']),
    ...mapState('AppStore', ['selectedApp'])
  },
  methods: {
    setInbox (inbox): void {
      // eslint-disable-next-line no-undef
      this.localInbox = structuredClone(inbox)
    },
    setInboxConfig (): void {
      if (this.localInbox.workingHours === undefined) {
        this.showWarning = true
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('attentionHours') })
      } else {
        let days:DayConfig[] = this.localInbox?.workingHours?.days || defaultWorkingHours.days

        if (days.length === 0) {
          days = [...defaultWorkingHours.days]
        }

        this.localInbox.workingHours.days = days.map(day => {
          // Si isOpen no existe o no es un booleano, se establece en true
          if (day.isOpen === undefined || typeof day.isOpen !== 'boolean') {
            day.isOpen = !day.isClosed
          }

          if (!day?.schedules || day?.schedules.length <= 0) {
            day.schedules = [{ from: '00:00', until: '00:00' }]
          }
          return day
        })
      }
    },
    getChatConfig (): void {
      // this.chatConfig = await ConversationsConfigController.getConfig()
      this.workingDays = this.localInbox.workingHours?.days || defaultWorkingHours.days
      this.rerender++
    },
    addSchedule (day): void {
      if (this.isUserReadonly) return
      day.schedules.push({ from: '00:00', until: '00:00' })
    },
    removeSchedule (day, scheduleIndex): void {
      if (this.isUserReadonly) return
      day.schedules.splice(scheduleIndex, 1)
      this.saveConfig()
    },
    isBefore (from, until): boolean {
      if (!from || !until) return true
      const fromMoment = moment(from, 'HH:mm')
      const untilMoment = moment(until, 'HH:mm')
      return fromMoment.isBefore(untilMoment)
    },
    checkSchedule (day): boolean {
      // check schedule
      let badSchedule = false
      if (day.isClosed) {
        return day.schedules.forEach((schedule, index) => {
          if (!this.isBefore(index > 0 ? day.schedules[index - 1].until : '00:00', schedule.from)) badSchedule = true
          if (!this.isBefore(schedule.from, schedule.until)) badSchedule = true
        })
      }
      if (badSchedule) {
        EventBus.$emit('toast', 'error', this.$t('badSchedule'))
      }
      return !badSchedule
    },
    isClosed (index): void {
      const day = this.localInbox.workingHours.days[index]
      day.isClosed = !day.isOpen
      this.saveConfig()
    },
    async saveConfig (): Promise<void> {
      if (this.isUserReadonly) return
      this.localInbox.workingHours.days.forEach((day, index) => {
        if (this.checkSchedule(day)) {
          this.$set(this.localInbox.workingHours.days, index, { ...day })
        }
      })
      this.$emit('inbox-updated', { workingHours: this.localInbox?.workingHours || [] })
      EventBus.$emit('inboxUpdated--spaces', this.localInbox)
      await this.writeActivityLog()
      this.getChatConfig()
      this.setInboxConfig()
    },
    async writeActivityLog (): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE,
          object: this.inbox.name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.WORKING_HOURS,
            newValue: this.localInbox.workingHours,
            oldValue: this.inbox.workingHours
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async handleTimeZoneChange (selectedTimeZone): Promise<void> {
      this.$emit('start-loading')
      await this.saveConfig()
      this.$emit('stop-loading')
    },
    isSelectedInbox (inbox: SpaceConfig): boolean {
      return this.selectedInboxId === inbox?._id
    }
  },
  watch: {
    localInbox: {
      handler (inbox) {
        this.inboxName = inbox?.name || ''
        this.message = inbox?.workingHours?.message || ''
        this.outOfServiceMessage = inbox?.workingHours?.outOfServiceMessage || ''
        this.inactivityMessage = inbox?.workingHours?.inactivityMessage || ''
        this.inactivitySeconds = inbox?.workingHours?.inactivitySeconds || '0'
        this.showSelector = false
        setTimeout(() => {
          this.showSelector = true
          this.setInboxConfig()
        }, 100)
      },
      immediate: true
    },
    inactivityMessage () {
      if (!this.awaitingInactivityMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingSearch = false
        }, 800)
      }
      this.awaitingSearch = true
    },
    outOfServiceMessage () {
      if (!this.awaitingOutOfServiceMessage) {
        setTimeout(async () => {
          await this.saveConfig()
          this.awaitingOutOfServiceMessage = false
        }, 800)
      }
      this.awaitingOutOfServiceMessage = true
    }
  }
}
