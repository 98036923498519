var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TemplateComposer"},[(_vm.canEditHeader)?_c('div',{staticClass:"TemplateComposer--row TemplateComposer--row--upperBorderRadius"},[_c('span',{staticClass:"TemplateComposer--row--label"},[_vm._v(" "+_vm._s(_vm.TemplateService.cleanTemplateMessage(_vm.templateHeader.text))+" ")]),_c('div',{staticClass:"TemplateComposer--row--textContainer"},[_c('KeybeText',{staticClass:"mb-3",attrs:{"transparent":true},on:{"input":_vm.handleTemplateEditing},model:{value:(_vm.headerParams[0].param),callback:function ($$v) {_vm.$set(_vm.headerParams[0], "param", $$v)},expression:"headerParams[0].param"}})],1)]):_vm._e(),_c('div',{staticClass:"TemplateComposer--header"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"TemplateComposer--header--button mr-3"},'div',attrs,false),on),[_c('span',{staticClass:"TemplateComposer--header--button--text"},[_vm._v(" "+_vm._s(_vm.$t("changeTemplate"))+" ")])])]}}]),model:{value:(_vm.selectingTemplate),callback:function ($$v) {_vm.selectingTemplate=$$v},expression:"selectingTemplate"}},[_c('TemplateSelector',{attrs:{"templates":_vm.templates},on:{"selectTemplate":function($event){_vm.selectedTemplate = $event;
          _vm.selectingTemplate = false;}}})],1),(_vm.outboundType)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"TemplateComposer--header--button mr-3"},'div',attrs,false),on),[_c('span',{staticClass:"TemplateComposer--header--button--text"},[_vm._v(" "+_vm._s(_vm.$t("copyCustomizedField"))+" ")])])]}}],null,false,1927652104),model:{value:(_vm.selectingParam),callback:function ($$v) {_vm.selectingParam=$$v},expression:"selectingParam"}},[_c('ParamsSelector',{attrs:{"params":_vm.params},on:{"selectParams":_vm.onSelectParams}})],1):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"TemplateComposer--header--button"},'div',attrs,false),on),[_c('div',{staticClass:"TemplateComposer--header--button--icon"},[_c('KeybeIcon',{attrs:{"icon":_vm.KeybeIconType.SMILE_FACE,"color":_vm.$vuetify.theme.dark ? 'white' : 'black'}})],1)])]}}]),model:{value:(_vm.openEmojiPicker),callback:function ($$v) {_vm.openEmojiPicker=$$v},expression:"openEmojiPicker"}},[_c('VEmojiPicker',{class:_vm.$vuetify.theme.dark ? 'picker-dark' : 'picker',attrs:{"label-search":_vm.$t('search'),"lang":"en-EN"},on:{"select":_vm.onSelectEmoji}})],1)],1),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTemplateLabel && _vm.isDefaultTemplate),expression:"showTemplateLabel && isDefaultTemplate"}],staticClass:"TemplateComposer--textarea--label",attrs:{"for":"TemplateComposer--textarea"}},[_vm._v(_vm._s(_vm.templateLabel))]),(_vm.isDefaultTemplate)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:_vm.idTextArea,staticClass:"TemplateComposer--textarea TemplateComposer--textarea--default",attrs:{"id":_vm.idTextArea,"placeholder":_vm.$t('yourTextHere')},domProps:{"value":(_vm.text)},on:{"scroll":_vm.handleScroll,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},_vm.handleTemplateEditing]}}):(_vm.selectedTemplate && !_vm.isDefaultTemplate)?_c('div',{staticClass:"TemplateComposer--textarea TemplateComposer--textarea--padding",class:{
      'TemplateComposer--textarea--lowerBorderRadius':
        !_vm.showMediaSelector && _vm.buttons.length === 0,
    },attrs:{"id":_vm.idTemplateInput},domProps:{"innerHTML":_vm._s(_vm.parseTemplateMessage(_vm.selectedTemplate.templateMessage))},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}}}):_vm._e(),(_vm.templateIsSequence)?_c('div',{staticClass:"TemplateComposer__cards"},[_c('h4',[_vm._v(_vm._s(_vm.$t('cards')))]),_vm._l((_vm.templateCarousel.cards),function(card,index){return _c('div',{key:(index + "-card"),staticClass:"TemplateComposer__card"},[_c('div',{staticClass:"TemplateComposer__image-selector"},[_c('MediaSelector',{staticClass:"w-full",attrs:{"accept":_vm.mediaHeaderAccept},on:{"fileUploaded":function($event){return _vm.onFileUploadedCard($event, index)}}})],1),_c('div',{staticClass:"TemplateComposer__input"},[_c('TemplateComposerCard',{key:(index + "-card-input"),attrs:{"card":card,"templateId":("" + (_vm.selectedTemplate.templateId) + index)},on:{"input":function($event){return _vm.setCardMessage(index)}}})],1),_vm._l((_vm.cardButtons(index)),function(button,j){return _c('div',{key:("templatebutton-" + j + "-" + index),staticClass:"w-full"},[_c('KeybeButton',{staticClass:"w-full my-3",attrs:{"text":button.text}})],1)})],2)})],2):_vm._e(),(_vm.showMediaSelector)?_c('div',{staticClass:"TemplateComposer--row TemplateComposer__footer",class:{
      'TemplateComposer--row--lowerBorderRadius': _vm.buttons.length === 0,
    }},[_c('span',{staticClass:"TemplateComposer--row--label"},[_vm._v(" "+_vm._s(_vm.mediaLabel)+" ")]),_c('MediaSelector',{attrs:{"accept":_vm.mediaHeaderAccept},on:{"fileUploaded":_vm.onFileUploaded}})],1):_vm._e(),_vm._l((_vm.buttons),function(button,index){return _c('div',{key:("templatebutton-" + index),staticClass:"w-full"},[(_vm.canEditButton(button))?_c('div',{staticClass:"TemplateComposer--row TemplateComposer--row--small"},[_c('span',{staticClass:"TemplateComposer--row--label"},[_vm._v(" "+_vm._s(button.text)+" ")])]):_vm._e(),(_vm.canEditButton(button))?_c('div',{staticClass:"TemplateComposer--row pb-4",class:{
        'TemplateComposer--row--lowerBorderRadius':
          index === _vm.buttons.length - 1,
      }},[_c('span',{staticClass:"TemplateComposer--row--label"},[_vm._v(" "+_vm._s(_vm.TemplateService.cleanTemplateMessage(button.url))+" ")]),_c('div',{staticClass:"TemplateComposer--row--textContainer"},[_c('KeybeText',{staticClass:"mb-3",attrs:{"transparent":true},on:{"input":_vm.handleTemplateEditing},model:{value:(button.param),callback:function ($$v) {_vm.$set(button, "param", $$v)},expression:"button.param"}})],1)]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }